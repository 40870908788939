var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-page__top"},[_c('h2',{staticClass:"admin-page__title"},[_vm._v("Личный кабинет Администратора")]),_c('div',{staticClass:"main-filter admin-page__filter"},[_c('router-link',{staticClass:"main-filter__button",class:{
        'main-filter__button_active':
          _vm.$route.name == 'AdminEmployeesTable' ||
          _vm.$route.name == 'AdminEmployeesView',
      },attrs:{"to":{ name: 'AdminEmployeesTable' }}},[_vm._v(" Сотрудники ")]),_c('router-link',{staticClass:"main-filter__button",class:{
        'main-filter__button_active':
          _vm.$route.name == 'AdminEmployeesCreateEmployeer',
      },attrs:{"to":{ name: 'AdminEmployeesCreateEmployeer' }}},[_vm._v(" Зарегестрировать нового сотрудника ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }